import {
  ECOM_PRODUCT_PRESCRIPTION,
  ECOM_PRODUCT_VIEW,
  MARKETPLACE_PRODUCT_TYPES,
} from "@/utils/constant";

import { useInputImageRenderer } from "@/@core/comp-functions/forms/form-utils";
import i18n from "@/libs/i18n";
import router from "@/router";
import appUtils from "@/utils/appUtils";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { $themeConfig } from "@themeConfig";
import { ref } from "@vue/composition-api";
import Vue from "vue";
import { useToast } from "vue-toastification/composition";

export default function useAddProduct() {
  const toast = useToast();
  const initialForm = ref({
    name: "",
    registration_number: null,
    sku: "",
    slug: "",
    ecom_brand_id: 0,
    dosage_form_id: 0,
    specification_id: 0,
    manufacturer_id: 0,
    origin_id: 0,
    categories_id: [],
    ingredient: "",
    short_desc: "",
    description: "",
    indication: "",
    contraindicated: "",
    notes: "",
    is_prescription_required: false,
    is_medicine: false,
    ecom_product_variants: [
      {
        code: appUtils.randomCode(10),
        price: null,
        ecom_unit_id: null,
        conversion_rate: 1,
        default: 2,
        ecom_unit: {
          price: null,
          ecom_unit_id: null,
        },
      },
    ],
    primary_image: null,
    // thumb_images: [],
    thumbnail_image: [],
    view: true,
    // default_price: 0,
    // default_unit_name: "",
    // default_number_unit: 0,
  });
  const form = ref(initialForm.value);
  const domain = ref($themeConfig.app.domain);
  const optionsEditor = ref({
    placeholder: "",
    theme: "snow",
  });
  const refInputEl = ref(null);
  const brandsOption = ref([]);
  const originsOption = ref([]);
  const dosageFormsOption = ref([]);
  const specificationsOption = ref([]);
  const manufacturersOption = ref([]);
  const unitsOption = ref([]);
  const categoryOption = ref([]);
  const previewImg = ref(null);
  const thumbsImg = ref([]);
  const refPrimaryImgEl = ref(null);
  const refInputThumbEl = ref(null);
  const addProductValidation = ref(null);
  const productId = ref(null);
  const productDetail = ref(null);
  const isLoading = ref(false);

  const userData = appUtils.getLocalUser();
  const default_params = {
    page_num: 1,
    page_size: 1000,
    supplier_id: userData?.supplierInfo?.id,
    sort_by: "",
    keyword: "",
    created_at: "",
    order: "desc",
  };
  const generateSlug = () => {
    if (!form.value.name) {
      return;
    }
    form.value.slug = appUtils.slugifyStr(form.value.name);
  };
  const fetchUnitList = async () => {
    return Vue.prototype.$rf
      .getRequest("MarketplaceRequest")
      .getUnits(default_params)
      .then((response) => {
        unitsOption.value = response.data.data;
      })
      .catch((e) => {
        console.log(e);
        toast({
          component: ToastificationContent,
          props: {
            title: i18n.t("lbl_error_fetching_units_data"),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
        return;
      });
  };
  const fetchDosageFormList = async () => {
    return Vue.prototype.$rf
      .getRequest("MarketplaceRequest")
      .getDosageForms(default_params)
      .then((response) => {
        dosageFormsOption.value = response.data.data;
      })
      .catch((e) => {
        console.log(e);
        toast({
          component: ToastificationContent,
          props: {
            title: i18n.t("lbl_error_fetching_dosage_forms_data"),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
        return;
      });
  };
  const fetchSpecificationList = async () => {
    return Vue.prototype.$rf
      .getRequest("MarketplaceRequest")
      .getSpecifications(default_params)
      .then((response) => {
        specificationsOption.value = response.data.data;
      })
      .catch((e) => {
        console.log(e);
        toast({
          component: ToastificationContent,
          props: {
            title: i18n.t("lbl_error_fetching_specifications_data"),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
        return;
      });
  };
  const fetchBrandList = async () => {
    return Vue.prototype.$rf
      .getRequest("MarketplaceRequest")
      .getBrands(default_params)
      .then((response) => {
        brandsOption.value = response.data.data;
      })
      .catch((e) => {
        console.log(e);
        toast({
          component: ToastificationContent,
          props: {
            title: i18n.t("lbl_error_fetching_brands_data"),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
        return;
      });
  };
  const fetchCategoryList = async () => {
    return Vue.prototype.$rf
      .getRequest("MarketplaceRequest")
      .getCategories(default_params)
      .then((response) => {
        categoryOption.value = response.data.data;
        return response;
      })
      .catch((e) => {
        console.log(e);
        toast({
          component: ToastificationContent,
          props: {
            title: i18n.t("lbl_error_fetching_categories_data"),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
        return;
      });
  };
  const fetchManufacturerList = async () => {
    return Vue.prototype.$rf
      .getRequest("MarketplaceRequest")
      .getManufacturers(default_params)
      .then((response) => {
        manufacturersOption.value = response.data.data;
      })
      .catch((e) => {
        console.log(e);
        toast({
          component: ToastificationContent,
          props: {
            title: i18n.t("lbl_error_fetching_manufacturers_data"),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
        return;
      });
  };
  const fetchOriginList = async () => {
    return Vue.prototype.$rf
      .getRequest("MarketplaceRequest")
      .getOrigins(default_params)
      .then((response) => {
        originsOption.value = response.data.data;
      })
      .catch((e) => {
        console.log(e);
        toast({
          component: ToastificationContent,
          props: {
            title: i18n.t("lbl_error_fetching_origins_data"),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
        return;
      });
  };

  const fetchProductById = async () => {
    await Vue.prototype.$rf
      .getRequest("MarketplaceRequest")
      .getProductDetailById(productId.value)
      .then((response) => {
        const product = response.data.ecom_product;
        const variants = product?.ecom_product_variants?.map((v) => {
          return {
            id: v.id,
            code: appUtils.randomCode(10),
            price: v.price,
            ecom_unit_id: v.ecom_unit_id,
            conversion_rate: v.conversion_rate,
            default: v.default,
            ecom_unit: unitsOption.value.find(
              (opt) => opt.id === v.ecom_unit_id
            ),
          };
        });
        productDetail.value = { ...product };
        if (product.thumbnail_image) {
          thumbsImg.value = JSON.parse(product?.thumbnail_image) || [];
        }
        previewImg.value = product?.primary_image || "";

        const is_medicine = product.type === MARKETPLACE_PRODUCT_TYPES.medicine;
        initialForm.value = {
          name: product.name,
          registration_number: product.registration_number,
          sku: product.sku,
          slug: product.slug,
          ecom_brand_id: product.ecom_brand_id,
          dosage_form_id: product.dosage_form_id,
          specification_id: product.specification_id,
          manufacturer_id: product.manufacturer_id,
          origin_id: product.origin_id,
          categories_id: product.ecom_product_category.map(
            (cate) => cate.ecom_category_id
          ),
          ingredient: product.ingredient,
          short_desc: product.short_desc,
          description: product.description,
          indication: product.indication,
          contraindicated: product.contraindicated,
          notes: product.notes,
          is_prescription_required:
            product.is_prescription_required ===
            ECOM_PRODUCT_PRESCRIPTION.required
              ? true
              : false,
          view: product.view === ECOM_PRODUCT_VIEW.view ? true : false,
          primary_image: product.primary_image,
          thumbnail_image:
            (product.thumbnail_image && JSON.parse(product.thumbnail_image)) ||
            [],
          ecom_product_variants: variants,
          is_medicine,
        };
        form.value = JSON.parse(JSON.stringify(initialForm.value));
      })
      .catch((e) => {
        console.log(e);
        toast({
          component: ToastificationContent,
          props: {
            title: i18n.t("lbl_error_fetching_product_detail_data"),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  const validateSelectedImage = (inputEl) => {
    if (!inputEl.value?.files[0] || inputEl.value?.files === null) {
      toast({
        component: ToastificationContent,
        props: {
          title: i18n.t("lbl_please_select_a_image_file"),
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
      inputEl.value = null;
      return false;
    }

    const fileSize = inputEl.value.files[0]?.size / 1024 / 1024;

    if (fileSize > 2) {
      toast({
        component: ToastificationContent,
        props: {
          title: i18n.t("lbl_image_file_size_exceeds_2_mb"),
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
      inputEl.value = null;
      return false;
    }
    return true;
  };

  const handleSelectPreviewImage = (e) => {
    if (!validateSelectedImage(refInputEl)) {
      form.value.primary_image = null;
      previewImg.value = null;
      return;
    }

    form.value.primary_image = refInputEl.value.files[0];

    const { inputImageRenderer } = useInputImageRenderer(
      refInputEl,
      (base64) => {
        previewImg.value = base64;
      }
    );
    inputImageRenderer();
  };

  const handleSelectThumbsImage = () => {
    if (!validateSelectedImage(refInputThumbEl)) return;

    form.value.thumbnail_image = [
      ...form.value.thumbnail_image,
      ...refInputThumbEl.value.files,
    ];

    for (let i = 0; i < refInputThumbEl.value.files?.length; i++) {
      const file = refInputThumbEl.value.files[i];
      const reader = new FileReader();

      reader.addEventListener(
        "load",
        () => {
          thumbsImg.value.push({
            url: reader.result,
          });
        },
        false
      );

      if (file) {
        reader.readAsDataURL(file);
      }
    }
  };

  const uploadFiles = async (file, product = {}) => {
    const params = {
      file,
      FolderTarget: `products`,
      IdTarget: product?.id || form.value.sku,
    };
    const formData = new FormData();

    for (const key in params) {
      formData.append(key, params[key]);
    }

    const res = await Vue.prototype.$rf
      .getRequest("CommonRequest")
      .uploadFileTenant(formData);
    return res.data.fileUrl;
  };

  const addProduct = () => {
    addProductValidation.value.validate().then(async (success) => {
      if (success) {
        isLoading.value = true;

        const {
          primary_image,
          thumbnail_image,
          ecom_product_variants,
          ...newForm
        } = form.value;
        const variantsRaw = ecom_product_variants?.map((v) => {
          return {
            price: +v.price.replaceAll(",", ""),
            ecom_unit_id: +v?.ecom_unit_id,
            conversion_rate:
              typeof v?.conversion_rate === "string"
                ? +v?.conversion_rate.replaceAll(",", "")
                : v?.conversion_rate,
            default: v.default,
          };
        });
        const files = [form.value.primary_image, ...form.value.thumbnail_image];
        const urlFilesRaw = await Promise.all(
          files.map(async (file) => {
            if (!(file instanceof File)) {
              return file;
            }

            const url = await uploadFiles(file);
            return url;
          })
        );
        const primary_image_raw = urlFilesRaw[0];
        const imagesRaw = urlFilesRaw.slice(1);
        const thumbnail_images = imagesRaw.map((img) => {
          return {
            url: img,
          };
        });

        const params = {
          ...newForm,
          is_prescription_required: form.value.is_prescription_required
            ? ECOM_PRODUCT_PRESCRIPTION.required
            : ECOM_PRODUCT_PRESCRIPTION.unRequired,
          view: form.value.view
            ? ECOM_PRODUCT_VIEW.view
            : ECOM_PRODUCT_VIEW.notView,
          primary_image: primary_image_raw,
          thumbnail_image: JSON.stringify(thumbnail_images),
          supplier_id: userData?.supplierInfo?.id,
          ecom_product_variants: variantsRaw,
          type: form.value.is_medicine
            ? MARKETPLACE_PRODUCT_TYPES.medicine
            : MARKETPLACE_PRODUCT_TYPES.others,
        };

        Vue.prototype.$rf
          .getRequest("MarketplaceRequest")
          .createProduct(params)
          .then(async (res) => {
            toast({
              component: ToastificationContent,
              props: {
                title: i18n.t("lbl_add_new_product_success"),
                icon: "CheckIcon",
                variant: "success",
              },
            });
            router.push({ name: "products-marketplace" });
          })
          .catch((e) => {
            console.log(e);
            toast({
              component: ToastificationContent,
              props: {
                title: i18n.t("lbl_error_add_new_product"),
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
            isLoading.value = false;
          })
          .finally(() => {
            setTimeout(() => {
              isLoading.value = false;
            }, 1000);
          });
      } else {
        const el = document.querySelector("small.text-danger:first-of-type");
        window.scrollTo({
          top: el.offsetTop,
          left: 0,
          behavior: "smooth",
        });
      }
    });
  };

  const updateProduct = () => {
    if (!productId.value || !productDetail.value.id) {
      toast({
        component: ToastificationContent,
        props: {
          title: i18n.t("lbl_error_does_not_get_product_detail_data"),
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
      return;
    }

    addProductValidation.value.validate().then(async (success) => {
      if (success) {
        isLoading.value = true;
        const { ecom_product_variants, ...newForm } = form.value;
        const variantsRaw = ecom_product_variants?.map((v) => {
          return {
            id: v.id,
            price: +v.price.replaceAll(",", ""),
            ecom_unit_id: +v?.ecom_unit_id,
            conversion_rate:
              typeof v?.conversion_rate === "string"
                ? +v?.conversion_rate.replaceAll(",", "")
                : v?.conversion_rate,
            default: v.default,
          };
        });

        const files = [form.value.primary_image, ...form.value.thumbnail_image];
        const urlFilesRaw = await Promise.all(
          files.map(async (file) => {
            if (!(file instanceof File)) {
              return file;
            }

            const url = await uploadFiles(file);
            return url;
          })
        );
        const primary_image_raw = urlFilesRaw[0];
        const imagesRaw = urlFilesRaw.slice(1);
        const thumbnail_images = imagesRaw.map((img) => {
          if (img.hasOwnProperty("url")) return img;
          return {
            url: img,
          };
        });

        const params = {
          ...newForm,
          primary_image: primary_image_raw,
          thumbnail_image: JSON.stringify(thumbnail_images),
          supplier_id: userData?.supplierInfo?.id,
          ecom_product_variants: variantsRaw,
          view: newForm.view === true ? 2 : 1,
          is_prescription_required:
            newForm.is_prescription_required === true ? 2 : 1,
          type: form.value.is_medicine
            ? MARKETPLACE_PRODUCT_TYPES.medicine
            : MARKETPLACE_PRODUCT_TYPES.others,
        };

        Vue.prototype.$rf
          .getRequest("MarketplaceRequest")
          .updateProduct(productId.value, params)
          .then(async (res) => {
            toast({
              component: ToastificationContent,
              props: {
                title: i18n.t("lbl_edit_product_success"),
                icon: "CheckIcon",
                variant: "success",
              },
            });
            router.push({ name: "products-marketplace" });
          })
          .catch((e) => {
            console.log(e);
            toast({
              component: ToastificationContent,
              props: {
                title: i18n.t("lbl_error_edit_product"),
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          })
          .finally(() => {
            setTimeout(() => {
              isLoading.value = false;
            }, 1000);
          });
      }
    });
  };

  const handleChangeIsMedicine = () => {
    form.value = {
      ...form.value,
      is_prescription_required: false,
      dosage_form_id: 0,
      indication: "",
      contraindicated: "",
      notes: "",
    };
  };

  return {
    initialForm,
    form,

    unitsOption,
    brandsOption,
    originsOption,
    specificationsOption,
    manufacturersOption,
    dosageFormsOption,
    categoryOption,

    fetchUnitList,
    fetchDosageFormList,
    fetchSpecificationList,
    fetchBrandList,
    fetchOriginList,
    fetchManufacturerList,
    fetchCategoryList,
    generateSlug,

    domain,
    optionsEditor,
    refPrimaryImgEl,
    refInputThumbEl,
    addProductValidation,
    productId,
    productDetail,
    isLoading,
    refInputEl,
    thumbsImg,
    previewImg,
    handleSelectPreviewImage,
    handleSelectThumbsImage,
    addProduct,
    updateProduct,
    fetchProductById,
    handleChangeIsMedicine,
  };
}
